import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/style.scss";
import Home from "pages/Home";
import About from "pages/About";
import Career from "pages/Career";
import TochModal from "components/modals/GetInTochModal";
import JobDetails from "pages/Career/Vacancy/JobDetails";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfService from "pages/TermsOfService";
import Layout from "pages/Layout";
import ErrorPopup from "components/ErrorPopup";
import NewVideoModal from "components/modals/NewVideoModal";
import "bootstrap/dist/js/bootstrap.min.js";
import HirePhpDeveloper from "pages/HirePhpDeveloper";
import HireUIUXDeveloper from "pages/HireUIUXDeveloper";
import HireFlutterDeveloper from "pages/HireFlutterDeveloper";
import HireVueJsDeveloper from "pages/HireVueJSDeveloper";
import HireFrontendDeveloper from "pages/HireFrontendDeveloper";
import HireBackendDeveloper from "pages/HireBackendDeveloper";
import HireFullstackDeveloper from "pages/HireFullstackDeveloper";
import HireAngularJSDeveloper from "pages/HireAngularJsDeveloper/HireAngularJsDeveloper";
import HireReactJSDeveloper from "pages/HireReactJSDeveloper/HireReactJSDeveloper";
import HireDedicatedDeveloper from "pages/HireDedicatedDeveloper";
import HireAndroidDeveloper from "pages/HireAndroidDeveloper";
import HireLaravelDeveloper from "pages/HireLaravelDeveloper";
import HireNodeJSDeveloper from "pages/HireNodeJSDeveloper";
import HireWordpressDeveloper from "pages/HireWordpressDeveloper";
import HireWebDesigner from "pages/HireWebDesigner";
import Culture from "pages/Culture";
import Blog from "pages/Blog";
import BlogDetail from "pages/Blog/BlogDetail/BlogDetail";

function App() {
  const reduxData = useSelector((state) => state.global);
  const { isOpen, isVacancy, isVideo } = reduxData || {};
  return (
    <>
      <BrowserRouter>
        <div className="pages-pera">
          <ErrorPopup />

          {isOpen && <TochModal />}
          {isVacancy && <JobDetails />}
          {/* {isVideo && <VideoModal />} */}
          {isVideo && <NewVideoModal />}

          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Home />
                </Layout>
              }
            />
            <Route
              path="/about"
              element={
                <Layout>
                  <About />
                </Layout>
              }
            />
            <Route
              path="/career"
              element={
                <Layout>
                  <Career />
                </Layout>
              }
            />
            <Route
              path="/life-sourcecube"
              element={
                <Layout>
                  <Culture />
                </Layout>
              }
            />
            <Route
              path="/blog"
              element={
                <Layout>
                  <Blog />
                </Layout>
              }
            />
            <Route
              path="/blog/blog-details/:id"
              element={
                <Layout>
                  <BlogDetail />
                </Layout>
              }
            />
            <Route
              path="/termsOfService"
              element={
                <Layout>
                  <TermsOfService />
                </Layout>
              }
            />
            <Route
              path="/privacyPolicy"
              element={
                <Layout>
                  <PrivacyPolicy />
                </Layout>
              }
            />
            <Route
              path="/hire-developer"
              element={
                <Layout>
                  <HireDedicatedDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-php-developer"
              element={
                <Layout>
                  <HirePhpDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-react-js-developer"
              element={
                <Layout>
                  <HireReactJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-ui-ux-developer"
              element={
                <Layout>
                  <HireUIUXDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-flutter-developer"
              element={
                <Layout>
                  <HireFlutterDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-angular-developer"
              element={
                <Layout>
                  <HireAngularJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-vue-developer"
              element={
                <Layout>
                  <HireVueJsDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-frontend-developer"
              element={
                <Layout>
                  <HireFrontendDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-backend-developer"
              element={
                <Layout>
                  <HireBackendDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-fullstack-developer"
              element={
                <Layout>
                  <HireFullstackDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-android-developer"
              element={
                <Layout>
                  <HireAndroidDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-laravel-developer"
              element={
                <Layout>
                  <HireLaravelDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-nodejs-developer"
              element={
                <Layout>
                  <HireNodeJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-nodejs-developer"
              element={
                <Layout>
                  <HireNodeJSDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-wordpress-developer"
              element={
                <Layout>
                  <HireWordpressDeveloper />
                </Layout>
              }
            />
            <Route
              path="/hire-web-designer"
              element={
                <Layout>
                  <HireWebDesigner />
                </Layout>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}
export default App;
