import icons from "utils/constants/icons";
import { useEffect, useState } from "react";
import celebrationImg from "../../../assets/images/celebration.jpg";
import celebrationImg1 from "../../../assets/images/celebration-1.JPG";
import celebrationImg2 from "../../../assets/images/celebration-2.JPG";
import celebrationImg3 from "../../../assets/images/celebration-3.JPG";
import celebrationImg4 from "../../../assets/images/celebration-4.JPG";
import ReactPlayer from "react-player";
import "glightbox/dist/css/glightbox.min.css";
import "./Celebration.scss";

function Celebration() {
  const [videoLink, setVideoLink] = useState(
    "https://www.youtube.com/embed/ezd2LKyX8gs?si=iB7Mjr4GFQS_CMvh"
  );
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {}, [videoLink]);

  const videoList = [
    {
      id: 0,
      videoPath:
        "https://www.youtube.com/embed/ezd2LKyX8gs?si=X3OBda8GBgLCG6qn?autoplay=1",

      image: celebrationImg1,
    },
    {
      id: 1,
      videoPath:
        "https://www.youtube.com/embed/PzGSTc9Bdxk?si=KSzk6V-sabTp3XdP?autoplay=1",
      image: celebrationImg2,
    },
    {
      id: 2,
      videoPath:
        "https://www.youtube.com/embed/I__K-Bverqs?si=MmTY74jVWBe5X7ro?autoplay=1",
      image: celebrationImg3,
    },
    {
      id: 3,
      videoPath:
        "https://www.youtube.com/embed/EBT33XMPea4?si=s7igLUv9a2M9HDcF?autoplay=1",
      image: celebrationImg4,
    },
    {
      id: 4,
      videoPath:
        "https://www.youtube.com/embed/z6E7zgdbdA0?si=vFEPHRiGCL1jqkUH?autoplay=1",
      image: celebrationImg4,
    },
  ];

  const CustomPlayIcon = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <img
        src={icons?.youtubeIcons}
        alt="youtube-icons"
        className="youtube-icon-w-h"
      />
    </div>
  );

  return (
    <div className="celebration-section">
      <div className="container">
        <h3 className="sub-title">View The Celebration Of Sourrcecube</h3>
        <div className="celebration-video">
          <ReactPlayer
            url={`${videoLink}`}
            light={!isPlaying ? celebrationImg : ""}
            width="100%"
            height="100%"
            playsinline="true"
            playing={isPlaying}
            playIcon={<CustomPlayIcon />}
            onClickPreview={() => {
              setIsPlaying(true);
            }}
          />
        </div>
        <div className="second-div">
          {videoList?.map((ele, index) => {
            return (
              <div
                key={index}
                className="second-video"
                onClick={() => {
                  setVideoLink(ele?.videoPath);
                  setIsPlaying(true);
                }}
              >
                <div className="sub-div-1">
                  <img src={ele?.image} alt="img" />
                </div>
                <div className="abs-img-1">
                  <img src={icons.youtubeIcons} alt="img" />
                </div>
              </div>
            );
          })}
        </div>{" "}
      </div>
    </div>
  );
}

export default Celebration;
