import logo from "assets/images/logo/logo.png";
import greenStar from "assets/images/icons/greenStar.webp";
import radioOne from "assets/images/common/radioOne.webp";
import radioTwo from "assets/images/common/radioTwo.webp";
import radioThree from "assets/images/common/radioThree.webp";
import radioFour from "assets/images/common/radioFour.webp";
import radioFive from "assets/images/common/radioFive.webp";
import phpIcon from "assets/images/icons/phpIcon.webp";
import mySqlIcon from "assets/images/icons/mySqlIcon.webp";
import laravelIcon from "assets/images/icons/laravelIcon.webp";
import codeIgnitorIcon from "assets/images/icons/codeIgnitorIcon.webp";
import mongoDBIcon from "assets/images/icons/mongoDBIcon.webp";
import cakePhpIcon from "assets/images/icons/cakePhpIcon.webp";
import goopss from "assets/images/icons/goopss.webp";
import UrgentDeal from "assets/images/icons/urgentdeal.svg";
import iferp from "assets/images/icons/iferp.webp";
import uHello from "assets/images/icons/uHello.webp";
import uStore from "assets/images/icons/uStore.webp";
import uGreen from "assets/images/icons/uGreen.webp";
import citronLogo from "assets/images/icons/citron-logo.webp";
import quickDrive from "assets/images/icons/quick-drive.webp";
import udenzLogo from "assets/images/icons/udenz-logo.webp";
import mohamedLogo from "assets/images/icons/mohamed-logo.webp";
import paydLogo from "assets/images/icons/payd-logo.webp";
import udenzPortfolio from "assets/images/common/udenz-portfolio.webp";
import paydPortfolio from "assets/images/common/payd-portfolio.webp";
import mohamedPortfolio from "assets/images/common/mohamed-portfolio.webp";
import quickDrivePortfolio from "assets/images/common/quick-drive-portfolio.webp";
import citronPortfolio from "assets/images/common/citron-portfolio.webp";
import iferpPortfolio from "assets/images/common/iferp-portfolio.webp";
import macBook from "assets/images/common/macBook.webp";
import www from "assets/images/icons/www.webp";
import playStore from "assets/images/icons/play-store.svg";
import cartIcon from "assets/images/icons/cartIcon.webp";
import googleIcon from "assets/images/common/gooleIcon.webp";
import clucthIcon from "assets/images/common/clucthIcon.webp";
import emailIcon from "assets/images/icons/emailIcon.webp";
import PhoneIcon from "assets/images/icons/PhoneIcon.webp";
import india from "assets/images/icons/india.webp";
import facebookIcon from "assets/images/icons/facebookIcon.webp";
import instagramIcon from "assets/images/icons/instagramIcon.webp";
import linkedInIcon from "assets/images/icons/linkedInIcon.webp";
import arrowRightIcon from "assets/images/icons/arrowRightIcon.webp";
import portDoctor from "assets/images/icons/port-doctor.webp";
import portFood from "assets/images/icons/port-food.webp";
import portMobile from "assets/images/icons/port-mobile.webp";
import portProgres from "assets/images/icons/port-progres.webp";
import MacbookImg from "assets/images/common/hero-macbook.webp";
import IphoneImg from "assets/images/common/hero-iphone.webp";
import GetStartRing from "assets/images/common/get-start-ring.svg";
import GetRingArrow from "assets/images/common/get-ring-arrow.webp";
import GetRingText from "assets/images/common/get-ring-text.webp";
import MacImg1 from "assets/images/common/mac-img-1.webp";
import MacImg2 from "assets/images/common/mac-img-2.webp";
import MacImg3 from "assets/images/common/mac-img-3.webp";
import MacImg4 from "assets/images/common/mac-img-4.webp";
import MacImg5 from "assets/images/common/mac-img-5.webp";
import PhiImg1 from "assets/images/common/phn-img1.webp";
import PhiImg2 from "assets/images/common/phn-img2.webp";
import PhiImg3 from "assets/images/common/phn-img3.webp";
import PhiImg4 from "assets/images/common/phn-img4.webp";
import PhiImg5 from "assets/images/common/phn-img5.webp";
import FlagIcon from "assets/images/icons/flag-icon.svg";
import CompanyAbout from "assets/images/icons/company-about-icon.svg";
import CompanyCareer from "assets/images/icons/company-career-icon.svg";
import CompanyWhy from "assets/images/icons/company-why-icon.svg";
import CompanyWork from "assets/images/icons/company-work-icon.svg";
import Whatsappicon from "assets/images/icons/whatsapp-icon.svg";
import Callicon from "assets/images/icons/call-icon.svg";
import TouchImage from "assets/images/common/touch-modal-img.webp";
import TechnologieImg from "assets/images/common/technologie-ad-img.webp";
import Technology1 from "assets/images/icons/technology1.webp";
import Technology2 from "assets/images/icons/technology2.webp";
import Technology3 from "assets/images/icons/technology3.webp";
import Technology4 from "assets/images/icons/technology4.webp";
import Technology5 from "assets/images/icons/technology5.webp";
import Technology6 from "assets/images/icons/technology6.webp";
import Technology7 from "assets/images/icons/technology7.webp";
import Technology8 from "assets/images/icons/technology8.webp";
import Technology9 from "assets/images/icons/technology9.webp";
import Technology10 from "assets/images/icons/technology10.webp";
import Technology11 from "assets/images/icons/technology11.webp";
import Technology12 from "assets/images/icons/technology12.webp";
import Technology13 from "assets/images/icons/technology13.webp";
import Technology14 from "assets/images/icons/technology4.webp";
import Technology15 from "assets/images/icons/technology15.webp";
import Technology16 from "assets/images/icons/technology16.webp";
import Technology17 from "assets/images/icons/technology17.webp";
import Technology18 from "assets/images/icons/technology18.webp";
import Technology19 from "assets/images/icons/technology19.webp";
import Technology20 from "assets/images/icons/technology20.webp";
import Technology21 from "assets/images/icons/technology21.webp";
import Technology22 from "assets/images/icons/technology22.webp";
import Technology23 from "assets/images/icons/technology23.webp";
import Technology24 from "assets/images/icons/technology24.webp";
import Technology25 from "assets/images/icons/technology25.webp";
import Technology26 from "assets/images/icons/technology26.webp";
import Technology27 from "assets/images/icons/technology27.webp";
import Technology28 from "assets/images/icons/technology28.webp";
import FillStar from "assets/images/icons/star-fill.webp";
import HalfStar from "assets/images/icons/star-half.webp";
import Star from "assets/images/icons/star.webp";
import PlayBtn from "assets/images/icons/play-btn.svg";
import Glassdoor from "assets/images/common/glassdoor.webp";
import AboutText from "assets/images/common/about-text.webp";
import MissionIcon from "assets/images/icons/our-mission-icon.webp";
import MissionCeo from "assets/images/common/our-mission-ceo1.webp";
import MissionBorder from "assets/images/common/our-mission-border.webp";
import TeamMember1 from "assets/images/common/team-member-1.webp";
import TeamMember2 from "assets/images/common/team-member-2.webp";
import TeamMember3 from "assets/images/common/team-member-3.webp";
import TeamMember4 from "assets/images/common/team-member-4.webp";
import TeamMember5 from "assets/images/common/team-member-5.webp";
import TeamMember6 from "assets/images/common/team-member-6.webp";
import TeamImg1 from "assets/images/icons/team-img-1.webp";
import TeamImg2 from "assets/images/icons/team-img-2.webp";
import TeamImg3 from "assets/images/icons/team-img-3.webp";
import TeamImg4 from "assets/images/icons/team-img-4.webp";
import TeamImg5 from "assets/images/icons/team-img-5.webp";
import TeamImg6 from "assets/images/icons/team-img-6.webp";
import TeamImg7 from "assets/images/icons/team-img-7.webp";
import TeamImg8 from "assets/images/icons/team-img-8.webp";
import TeamPlusImg from "assets/images/icons/team-img-plus.svg";
import JourneyRound from "assets/images/common/journey-round.svg";
import Services1 from "assets/images/icons/services-1.svg";
import Services2 from "assets/images/icons/services-2.svg";
import Services3 from "assets/images/icons/services-3.svg";
import Services4 from "assets/images/icons/services-4.svg";
import Services5 from "assets/images/icons/services-5.svg";
import Services21 from "assets/images/icons/services-21.svg";
import Services22 from "assets/images/icons/services-22.svg";
import Services23 from "assets/images/icons/services-23.svg";
import Services24 from "assets/images/icons/services-24.svg";
import Services31 from "assets/images/icons/services-31.svg";
import Services32 from "assets/images/icons/services-32.svg";
import Services33 from "assets/images/icons/services-33.svg";
import Services34 from "assets/images/icons/services-34.svg";
import Services35 from "assets/images/icons/services-35.svg";
import Services36 from "assets/images/icons/services-36.svg";
import Services51 from "assets/images/icons/services-51.svg";
import servicesDevop1 from "assets/images/icons/services-devop1.svg";
import servicesDevop2 from "assets/images/icons/services-devop2.svg";
import servicesDevop3 from "assets/images/icons/services-devop3.svg";
import servicesDevop4 from "assets/images/icons/services-devop4.svg";
import servicesDevop5 from "assets/images/icons/services-devop5.svg";
import gImage1 from "assets/images/common/gImage1.webp";
import gImage2 from "assets/images/common/gImage2.webp";
import gImage3 from "assets/images/common/gImage3.webp";
import gImage4 from "assets/images/common/gImage4.webp";
import gImage5 from "assets/images/common/g1rimage7.webp";
import gImage6 from "assets/images/common/g2rimage7.webp";
import g2Image1 from "assets/images/common/g2Image1.webp";
import g2Image2 from "assets/images/common/g2Image2.webp";
import g2Image3 from "assets/images/common/g2Image3.webp";
import g2Image4 from "assets/images/common/g2Image4.webp";
import g2Image6 from "assets/images/common/g2Image6.webp";
import gallery1 from "assets/images/common/gallery-1.webp";
import gallery2 from "assets/images/common/gallery-2.webp";
import gallery3 from "assets/images/common/gallery-3.webp";
import gallery4 from "assets/images/common/gallery-4.webp";
import gallery5 from "assets/images/common/gallery-5.webp";
import gallery6 from "assets/images/common/gallery-6.webp";
import gallery7 from "assets/images/common/gallery-7.webp";
import gallery8 from "assets/images/common/gallery-8.webp";
import gallery9 from "assets/images/common/gallery-9.webp";
import gallery10 from "assets/images/common/gallery-10.webp";
import gallery11 from "assets/images/common/gallery-11.webp";
import gallery12 from "assets/images/common/gallery-12.webp";
import gallery13 from "assets/images/common/gallery-13.webp";
import gallery14 from "assets/images/common/gallery-14.webp";
import gallery15 from "assets/images/common/gallery-15.webp";
import gallery16 from "assets/images/common/gallery-16.webp";
import learningIcon from "assets/images/icons/learningIcon.webp";
import motovationalIcon from "assets/images/icons/motovationalIcon.webp";
import holidayIcon from "assets/images/icons/holidayIcon.webp";
import celebrationIcon from "assets/images/icons/celebrationIcon.webp";
import salaryIcon from "assets/images/icons/salaryIcon.webp";
import environmentIcon from "assets/images/icons/environmentIcon.webp";
import professionalIcon from "assets/images/icons/professionalIcon.webp";
import loyaltyIcon from "assets/images/icons/loyaltyIcon.webp";
import CareerHeroBg from "assets/images/common/career-hero-bg.webp";
import CareerBigRing from "assets/images/common/career-big-ring.webp";
import CareerSmallRing from "assets/images/common/career-small-ring.webp";
import CareerHeroText from "assets/images/common/career-text.webp";
import VacancyFresh from "assets/images/common/vacancy-freshers-img.webp";
import VacancyExp from "assets/images/common/vacancy-exp-img.webp";
import VacancyArrow from "assets/images/common/vacancy-arrow.svg";
import ServicesWeb from "assets/images/icons/services-web.svg";
import ServicesApp from "assets/images/icons/services-app.svg";
import ServicesJs from "assets/images/icons/services-js.svg";
import ServicesEcommerce from "assets/images/icons/services-ecommerce.svg";
import ServicesDevops from "assets/images/icons/services-devops.svg";
import Business1 from "assets/images/icons/business-icon-1.svg";
import Business2 from "assets/images/icons/business-icon-2.svg";
import Business3 from "assets/images/icons/business-icon-3.svg";
import Business4 from "assets/images/icons/business-icon-4.svg";
import Business5 from "assets/images/icons/business-icon-5.svg";
import Business6 from "assets/images/icons/business-icon-6.svg";
import Business7 from "assets/images/icons/business-icon-7.svg";
import Business8 from "assets/images/icons/business-icon-8.svg";
import Business9 from "assets/images/icons/business-icon-9.svg";
import Business10 from "assets/images/icons/business-icon-10.svg";
import Business11 from "assets/images/icons/business-icon-11.svg";
import Business12 from "assets/images/icons/business-icon-12.svg";
import Business13 from "assets/images/icons/business-icon-13.svg";
import Business14 from "assets/images/icons/business-icon-14.svg";
import Business15 from "assets/images/icons/business-icon-15.svg";
import Business16 from "assets/images/icons/business-icon-16.svg";
import detail1 from "assets/images/icons/Upload icon.svg";
import detail2 from "assets/images/icons/company_location.svg";
import detail3 from "assets/images/icons/company_time.svg";
import detail4 from "assets/images/icons/company_workingdays.svg";
import detail5 from "assets/images/icons/graduate.svg";
import detail6 from "assets/images/icons/fulltime_job.svg";
import detail7 from "assets/images/icons/experience.svg";
import detail8 from "assets/images/icons/user-white.svg";
import resumeUpload from "assets/images/icons/resume_upload.svg";
import resumeUploadSuccess from "assets/images/icons/resume_upload_success.svg";
import trashIcon from "assets/images/icons/trash-icon.svg";
import top100Logo from "assets/images/icons/top-100-logo.webp";
import countries_icon1 from "assets/images/icons/countries-icon1.svg";
import countries_icon2 from "assets/images/icons/countries-icon2.svg";
import countries_icon3 from "assets/images/icons/countries-icon3.svg";
import countries_icon4 from "assets/images/icons/countries-icon4.svg";
import project_icon1 from "assets/images/icons/project-icon1.svg";
import project_icon2 from "assets/images/icons/project-icon2.svg";
import project_icon3 from "assets/images/icons/project-icon3.svg";
import project_icon4 from "assets/images/icons/project-icon4.svg";
import country1 from "assets/images/icons/country-icon1.webp";
import country2 from "assets/images/icons/country-icon2.webp";
import country3 from "assets/images/icons/country-icon3.webp";
import country4 from "assets/images/icons/country-icon4.webp";
import country5 from "assets/images/icons/country-icon5.webp";
import country6 from "assets/images/icons/country-icon6.webp";
import country7 from "assets/images/icons/country-icon7.webp";
import country8 from "assets/images/icons/country-icon8.webp";
import country9 from "assets/images/icons/country-icon9.webp";
import checkedIcon from "assets/images/icons/checked-icon.svg";
import hireResourcesFormIcon from "assets/images/icons/hireresources-formicon.svg";
import hireResourcesStap1 from "assets/images/icons/step-1-icon.svg";
import hireResourcesStap2 from "assets/images/icons/hireresources-stap2.svg";
import hireResourcesStap3 from "assets/images/icons/hireresources-stap3.svg";
import hireResourcesStap4 from "assets/images/icons/hireresources-stap4.svg";
import hireResourGreenDot from "assets/images/icons/hireresources-greendot.svg";
import hireResourWhiteDot from "assets/images/icons/hireresources-whitedot.svg";
import hireResourcesText from "assets/images/common/hireresources-text.webp";
import hireResourcesCareIcon from "assets/images/common/hireresources-careicon.webp";
import hireResourcesDeveloperImg from "assets/images/common/hireresources-developerimg.webp";
import hireResourcesFormImg from "assets/images/common/hireresources-formimg.webp";
import hireResourcesSecure from "assets/images/common/hireresources-secureicon.webp";
import ArrowBottom from "assets/images/icons/arrow-bottom.svg";
import stepUpArrow from "assets/images/icons/stepuparrow.svg";
import stepDownArrow from "assets/images/icons/stepdownarrow.svg";
import fullstack from "assets/images/icons/fullstack.png";
import backend from "assets/images/icons/backend.png";
import frontend from "assets/images/icons/frontend.png";
import angular from "assets/images/icons/angular.png";
import reactjs from "assets/images/icons/reactjs.png";
import vuejs from "assets/images/icons/vuejs1.png";
import figma from "assets/images/icons/figma.png";
import dedicated from "assets/images/icons/dedicated.png";
import php from "assets/images/icons/php.png";
import flutter from "assets/images/icons/flutter.png";
import android from "assets/images/icons/android.png";
import laravel from "assets/images/icons/laravel.png";
import nodejs from "assets/images/icons/nodejs.png";
import wordpress from "assets/images/icons/wordpress.png";
import webdesigner from "assets/images/icons/webdesigner.png";
import heroBanner1 from "assets/images/common/hero-banner-1.webp";
import heroBanner2 from "assets/images/common/hero-banner-2.webp";
import heroBanner3 from "assets/images/common/hero-banner-3.webp";
import heroBanner4 from "assets/images/common/hero-banner-4.webp";
import heroBannerM1 from "assets/images/common/hero-banner-m-1.webp";
import heroBannerM2 from "assets/images/common/hero-banner-m-2.webp";
import heroBannerM3 from "assets/images/common/hero-banner-m-3.webp";
import heroBannerM4 from "assets/images/common/hero-banner-m-4.webp";
import st1 from "assets/images/common/st-1.webp";
import st2 from "assets/images/common/st-2.webp";
import st3 from "assets/images/common/st-3.webp";
import st4 from "assets/images/common/st-4.webp";
import st5 from "assets/images/common/st-5.webp";
import st6 from "assets/images/common/st-6.webp";
import st7 from "assets/images/common/st-7.webp";
import st8 from "assets/images/common/st-8.webp";
import st9 from "assets/images/common/st-9.webp";
import st10 from "assets/images/common/st-10.webp";
import st11 from "assets/images/common/st-11.webp";
import st12 from "assets/images/common/st-12.webp";
import st13 from "assets/images/common/st-13.webp";
import st14 from "assets/images/common/st-14.webp";
import st15 from "assets/images/common/st-15.webp";
import st16 from "assets/images/common/st-16.webp";
import st17 from "assets/images/common/st-17.webp";
import st18 from "assets/images/common/st-18.webp";
import st19 from "assets/images/common/st-19.webp";
import st20 from "assets/images/common/st-20.webp";
import st21 from "assets/images/common/st-21.webp";
import st22 from "assets/images/common/st-22.webp";
import st23 from "assets/images/common/st-23.webp";
import st24 from "assets/images/common/st-24.webp";
import communicationImage from "assets/images/icons/communication_image.jpg";
import learning from "assets/images/icons/learning.avif";
import querySession from "assets/images/icons/query-session.jpg";
import blogSlider from "assets/images/icons/blog-slider.png";
import locationIcons from "assets/images/icons/location-pointer.png";
import searchIcons from "assets/images/icons/search.png";
import upRightArrow from "assets/images/icons/up-right.png";
import youtubeIcons from "assets/images/icons/youtube.png";

export const icons = {
    webdesigner,
    wordpress,
    nodejs,
    laravel,
    android,
    backend,
    frontend,
    angular,
    reactjs,
    vuejs,
    fullstack,
    figma,
    php,
    flutter,
    top100Logo,
    dedicated,
    trashIcon,
    gallery16,
    gallery15,
    gallery14,
    gallery13,
    gallery12,
    gallery11,
    gallery10,
    gallery9,
    gallery8,
    gallery7,
    gallery6,
    gallery5,
    gallery4,
    gallery3,
    gallery2,
    gallery1,
    servicesDevop1,
    servicesDevop2,
    servicesDevop3,
    servicesDevop4,
    servicesDevop5,
    resumeUpload,
    resumeUploadSuccess,
    UrgentDeal,
    VacancyFresh,
    CareerHeroText,
    VacancyArrow,
    VacancyExp,
    CareerSmallRing,
    CareerBigRing,
    CareerHeroBg,
    playStore,
    logo,
    Callicon,
    Glassdoor,
    Whatsappicon,
    greenStar,
    radioOne,
    radioTwo,
    radioThree,
    radioFive,
    radioFour,
    phpIcon,
    mySqlIcon,
    laravelIcon,
    codeIgnitorIcon,
    mongoDBIcon,
    cakePhpIcon,
    goopss,
    iferp,
    uHello,
    uStore,
    uGreen,
    citronLogo,
    quickDrive,
    udenzLogo,
    mohamedLogo,
    paydLogo,
    udenzPortfolio,
    paydPortfolio,
    mohamedPortfolio,
    quickDrivePortfolio,
    citronPortfolio,
    iferpPortfolio,
    macBook,
    www,
    cartIcon,
    googleIcon,
    clucthIcon,
    emailIcon,
    PhoneIcon,
    india,
    facebookIcon,
    instagramIcon,
    linkedInIcon,
    arrowRightIcon,
    portDoctor,
    portFood,
    portMobile,
    portProgres,
    MacbookImg,
    IphoneImg,
    GetStartRing,
    GetRingArrow,
    GetRingText,
    MacImg1,
    MacImg2,
    MacImg3,
    MacImg4,
    MacImg5,
    PhiImg1,
    PhiImg2,
    PhiImg3,
    PhiImg4,
    PhiImg5,
    FlagIcon,
    CompanyAbout,
    CompanyCareer,
    CompanyWhy,
    CompanyWork,
    TouchImage,
    TechnologieImg,
    Technology1,
    Technology2,
    Technology3,
    Technology4,
    Technology5,
    Technology6,
    Technology7,
    Technology8,
    Technology9,
    Technology10,
    Technology11,
    Technology12,
    Technology13,
    Technology14,
    Technology15,
    Technology16,
    Technology17,
    Technology18,
    Technology19,
    Technology20,
    Technology21,
    Technology22,
    Technology23,
    Technology24,
    Technology25,
    Technology26,
    Technology27,
    Technology28,
    FillStar,
    HalfStar,
    Star,
    PlayBtn,
    AboutText,
    MissionIcon,
    MissionCeo,
    MissionBorder,
    TeamMember1,
    TeamMember2,
    TeamMember3,
    TeamMember4,
    TeamMember5,
    TeamMember6,
    TeamImg1,
    TeamImg2,
    TeamImg3,
    TeamImg4,
    TeamImg5,
    TeamImg6,
    TeamImg7,
    TeamImg8,
    TeamPlusImg,
    JourneyRound,
    Services1,
    Services2,
    Services3,
    Services4,
    Services5,
    Services21,
    Services22,
    Services23,
    Services24,
    Services31,
    Services32,
    Services33,
    Services34,
    Services35,
    Services36,
    Services51,
    gImage1,
    gImage2,
    gImage3,
    gImage6,
    gImage5,
    gImage4,
    g2Image1,
    g2Image2,
    g2Image3,
    g2Image4,
    g2Image6,
    learningIcon,
    motovationalIcon,
    holidayIcon,
    celebrationIcon,
    salaryIcon,
    environmentIcon,
    professionalIcon,
    loyaltyIcon,
    ServicesWeb,
    ServicesApp,
    ServicesJs,
    ServicesEcommerce,
    ServicesDevops,
    Business1,
    Business2,
    Business3,
    Business4,
    Business5,
    Business6,
    Business7,
    Business8,
    Business9,
    Business10,
    Business11,
    Business12,
    Business13,
    Business14,
    Business15,
    Business16,
    detail1,
    detail2,
    detail3,
    detail4,
    detail5,
    detail6,
    detail7,
    detail8,
    countries_icon1,
    countries_icon2,
    countries_icon3,
    countries_icon4,
    project_icon1,
    project_icon2,
    project_icon3,
    project_icon4,
    country1,
    country2,
    country3,
    country4,
    country5,
    country6,
    country7,
    country8,
    country9,
    checkedIcon,
    hireResourcesFormIcon,
    hireResourGreenDot,
    hireResourWhiteDot,
    hireResourcesStap1,
    hireResourcesStap2,
    hireResourcesStap3,
    hireResourcesStap4,
    hireResourcesText,
    hireResourcesCareIcon,
    hireResourcesDeveloperImg,
    hireResourcesFormImg,
    hireResourcesSecure,
    ArrowBottom,
    stepUpArrow,
    stepDownArrow,
    heroBanner1,
    heroBanner2,
    heroBanner3,
    heroBanner4,
    heroBannerM1,
    heroBannerM2,
    heroBannerM3,
    heroBannerM4,
    st1,
    st2,
    st3,
    st4,
    st5,
    st6,
    st7,
    st8,
    st9,
    st10,
    st11,
    st12,
    st13,
    st14,
    st15,
    st16,
    st17,
    st18,
    st19,
    st20,
    st21,
    st22,
    st23,
    st24,
    communicationImage,
    learning,
    querySession,
    blogSlider,
    locationIcons,
    searchIcons,
    upRightArrow,
    youtubeIcons,
};

export default icons;
