import "./SearchInput.scss";
import icons from "utils/constants/icons";

const SearchInput = ({
  className,
  error,
  placeholder,
  onChange,
  name,
  value,
  reference,
  id,
  disabled,
  autoFocus,
  onBlur,
  type,
  maxNumber,
}) => {
  return (
    <div id="Search-input-container">
      <div className="input-container">
        <input
          id={id}
          autoComplete="off"
          placeholder={placeholder}
          name={name}
          type={type || "text"}
          className={`text-input ${className || "text-input"}`}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          ref={reference}
          disabled={disabled}
          autoFocus={autoFocus}
          max={maxNumber}
          min={0}
        />
        <img src={icons?.searchIcons} className="search-icon" alt="search" />
        {error && (
          <span className="text-12-400 pt-1">
            <span style={{ color: "red" }}>{error}</span>
          </span>
        )}
      </div>
    </div>
  );
};

export default SearchInput;
